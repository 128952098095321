import { render, staticRenderFns } from "./AddBudget.vue?vue&type=template&id=45fb6b69"
import script from "./AddBudget.vue?vue&type=script&lang=js"
export * from "./AddBudget.vue?vue&type=script&lang=js"
import style0 from "./AddBudget.vue?vue&type=style&index=0&id=45fb6b69&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports