<template>
  <div>
    <b-row class="mb-1" align-h="end">
      <b-col md="2">
        <b-button
          @click="downloadSampleExcel"
          v-ripple.400="'rgba(0, 207, 232, 0.15)'"
          variant="outline-info"
          pill
          size="sm"
        >
          Sample Excel
        </b-button>
      </b-col>
    </b-row>
    <b-card>
      <b-form>
        <b-row class="mb-1">
          <b-col md="4">
            <v-select
              v-model="form.movie"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="movie_list"
              label="title"
              value="movie_id"
              placeholder="Select Movie"
              class="mb-1"
            />
          </b-col>
          <b-col md="4">
            <b-form-file
              class="mb-1"
              id="file"
              accept=".xlsx, .xls"
              v-model="form.uploaded_file"
            />
          </b-col>
          <b-col md="3">
            <b-button
              class="mb-1"
              @click="uploadBudget"
              variant="relief-primary"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              >Upload Budget</b-button
            >
          </b-col>
        </b-row>
      </b-form>
      <hr />

      <div class="d-flex mb-2">
        <feather-icon icon="UserIcon" size="19" />
        <h4 class="mb-0 ml-50">Manual Input</h4>
      </div>

      <b-form class="mb-2">
        <div
          v-for="(manual_input, index) in form.manual_inputs"
          :key="'manual_input' + index"
        >
          <b-row>
            <b-col md="3">
              <b-form-group label="Category">
                <v-select
                  v-model="form.manual_inputs[index].category"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="category_list"
                  label="lbl_name"
                  value="lbl_id"
                  placeholder="Please Select"
                  class="select-size-sm"
                />
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group label="Territory">
                <v-select
                  v-model="form.manual_inputs[index].territory"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="territory_list"
                  label="lbl_name"
                  value="lbl_id"
                  placeholder="Please Select"
                  class="select-size-sm"
                />
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group label="Budget Code">
                <b-form-input
                  v-model="form.manual_inputs[index].budget_code"
                  class="form-control"
                  size="sm"
                />
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group label="Budget Amount">
                <b-form-input
                  v-model="form.manual_inputs[index].budget_amount"
                  class="form-control"
                  size="sm"
                />
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group label="Unit">
                <b-form-input
                  v-model="form.manual_inputs[index].unit"
                  class="form-control"
                  size="sm"
                />
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group label="Rate">
                <b-form-input
                  v-model="form.manual_inputs[index].rate"
                  class="form-control"
                  size="sm"
                />
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group label="Explanation">
                <b-form-input
                  v-model="form.manual_inputs[index].explanation"
                  class="form-control"
                  size="sm"
                />
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group label="Fixed/Variable">
                <v-select
                  v-model="form.manual_inputs[index].fix_var"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="fix_var"
                  label="name"
                  value="id"
                  placeholder="Please Select"
                  class="select-size-sm"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <hr />
        </div>
        <div class="d-flex justify-content-center">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            pill
            @click="
              () => {
                this.form.manual_inputs = [...this.form.manual_inputs, {}];
              }
            "
            >Add More</b-button
          >
        </div>
      </b-form>

      <b-row align-h="end">
        <!-- <b-col cols="12" sm="4" md="1"> -->
        <b-button
          @click="saveManualInputs"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          class="mr-1 mb-1"
          variant="primary"
        >
          Save
        </b-button>
        <!-- </b-col> -->
        <!-- <b-col cols="12" sm="4" md="1"> -->
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="reset"
          variant="outline-secondary"
          class="mb-1"
          @click="resetForm"
        >
          Reset
        </b-button>
        <!-- </b-col>  -->
        <!-- <b-button
          @click="saveManualInputs"
          variant="gradient-success"
          class="mr-1"
          >Save</b-button
        >

        <b-button
          variant="gradient-danger"
          @click="
            () => {
              this.form.manual_inputs = [{}];
            }
          "
          >Reset</b-button
        > -->
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BDropdown,
  BDropdownItem,
  BFormFile,
  BAlert,
  BBadge,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContentVue from "@core/components/toastification/ToastificationContent.vue";

import { getAllMovie } from "@/apiServices/MovieServices";
import { AddBudget } from "@/apiServices/BudgetServices";
import { getAllData } from "@/apiServices/MastersServices";
import axios from "axios";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormFile,
    BAlert,
    BBadge,
    BSpinner,
    vSelect,
    ToastificationContentVue,
  },

  directives: {
    Ripple,
  },

  data() {
    return {
      BASE_URL: process.env.VUE_APP_BASEURL,
      movie_list: [],
      category_list: [],
      territory_list: [],
      fix_var: [
        { name: "Fixed", value: "fixed", id: 1 },
        { name: "Variable", value: "variable", id: 2 },
      ],
      form: {
        movie: null,
        uploaded_file: null,
        manual_inputs: [{}],
      },
    };
  },

  methods: {
    resetForm() {
      // this.form = {
      //   movie: null,
      //   uploaded_file: null,
      //   manual_inputs: [{}],
      // };

      this.form.movie = null;
      this.form.uploaded_file = null;
      this.form.manual_inputs = [{}];
    },
    downloadSampleExcel() {
      fetch(this.BASE_URL + "/budget/downloadSampleBudget", {
        headers: {
          "content-type": "application/Json",
          authorization: localStorage.getItem("access_token"),
          "x-access-token": localStorage.getItem("access_token"),
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          var _url = window.URL.createObjectURL(blob);
          window.open(_url, "_blank").focus();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getMovies() {
      const response = await getAllMovie();
      if (response.status == 200) {
        this.movie_list = response.data.data.data;
      }
    },

    async uploadBudget() {
      if (!this.form.movie || !this.form.movie.movie_id) {
        return this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Please select movie!",
            icon: "EditIcon",
            variant: "failure",
          },
        });
      }

      if (!this.form.uploaded_file) {
        return this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Please upload the Excel file!",
            icon: "EditIcon",
            variant: "failure",
          },
        });
      }

      const url = this.BASE_URL + "/budget/uploadExcel";
      const formData = new FormData();
      formData.append("file", this.form.uploaded_file);
      formData.append("movie_id", this.form.movie.movie_id);

      const config = {
        headers: {
          "content-type": "multipart/form-data",
          authorization: localStorage.getItem("access_token"),
        },
      };
      await axios.post(url, formData, config).then((response) => {
        return this.$toast({
          component: ToastificationContentVue,
          props: {
            title: response.data.message || "",
            icon: "EditIcon",
            variant: "failure",
          },
        });
      });
    },

    async getAllCategory() {
      const response = await getAllData("categorys");
      if (response.status == 200) {
        this.category_list = response.data.data.rows;
      }
    },

    async getAllTerritory() {
      const response = await getAllData("territory");
      if (response.status == 200) {
        this.territory_list = response.data.data.rows;
      }
    },

    async saveManualInputs() {
      if (!this.form.movie || !this.form.movie.movie_id) {
        return this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Please select movie!",
            icon: "EditIcon",
            variant: "failure",
          },
        });
      }

      const budget_items = this.form.manual_inputs.map((item) => ({
        budget_code: item.budget_code,
        budgeted_amount: item.budget_amount,
        category: item.category.lbl_id,
        explanation: item.explanation,
        fixedvariable: item.fix_var.id,
        rate: item.rate,
        terriorty: item.territory.lbl_id,
        unit: item.unit,
      }));

      const response = await AddBudget({
        budget_items,
        movie_id: this.form.movie.movie_id,
      });

      this.resetForm();
      if (response.status == 200) {
        return this.$toast({
          component: ToastificationContentVue,
          props: {
            title: response.data.message || "",
            icon: "EditIcon",
            variant: "success",
          },
        });
      } else {
        return this.$toast({
          component: ToastificationContentVue,
          props: {
            title: response.data.console.error || "",
            icon: "EditIcon",
            variant: "failure",
          },
        });
      }
    },
  },

  beforeMount() {
    this.getMovies();
    this.getAllCategory();
    this.getAllTerritory();
  },
};
</script>

<style lang="sass">
@import "@core/scss/vue/libs/vue-select.scss"
</style>
